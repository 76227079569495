import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject, from, map, mergeMap, take, tap } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PostRequest } from '../api/methods';
import { Post } from '../model/post';
import { isPlatformBrowser } from '@angular/common';
import { RoutingService } from './routing.service';

@Injectable({
  providedIn: 'root'
})
export class PostsService {
  private platformId = inject(PLATFORM_ID);
  private gateUrl = environment.postUrl + '/v1';
  public updatePostSubject = new Subject<any>();
  updatePostSubject$ = this.updatePostSubject.asObservable();
  public feedPostsList = new BehaviorSubject<any>([]);
  feedPostsList$ = this.feedPostsList.asObservable();
  public feedPostSettings = new BehaviorSubject<any>({limit: 10, page: 1});
  feedPostSettings$ = this.feedPostSettings.asObservable();
  public feedPostsTotalAmount = new BehaviorSubject<number>(0);
  public currentPostVisited = new BehaviorSubject<string>(null);
  public currentPostVisited$ = this.currentPostVisited.asObservable();
  public feedReloadSubject = new BehaviorSubject<boolean>(false);
  public feedReload$ = this.feedReloadSubject.asObservable();
  public postSavingProcessingSubject = new BehaviorSubject<boolean>(false);
  public postSavingProcessing$ = this.postSavingProcessingSubject.asObservable();


  constructor(
    private http: HttpClient,
    private routesService: RoutingService,
  ) {}

  public getPosts(settings:{limit: number, page: number, tags?: string[]}): Observable<any> {

    let params = new HttpParams()
      .set('limit', settings.limit.toString())
      .set('page', settings.page.toString());
      if (settings.tags) {
        settings.tags.forEach(tag => {
          params = params.append('tags', tag);
        });
      }
      return this.http.get<any>(`${this.gateUrl}/posts`, {
        params,
        withCredentials: true
      }).pipe(
        map(response => this.sortPostImagesByPosition(response))
      );
  }

  public getQueuePosts(date: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/queue/list`, {'month': date}, { withCredentials: true });
}

  public getPostById(id: string): Observable<any> {
      return this.http.get<any>(`${this.gateUrl}/posts/${id}`, { withCredentials: true });
  }

  public getTagsRecommendationList(name: string): Observable<any> {
      return this.http.get<any>(`${this.gateUrl}/posts/tags/${name}`, { withCredentials: true });
  }

  public getTagsPopularList(limit: number, page: number): Observable<any> {
    const params = new HttpParams()
      .set('limit', limit.toString())
      .set('page', page.toString());
      return this.http.get<any>(`${this.gateUrl}/posts/tags/popular`,{
        params,
        withCredentials: true
      });
  }

  public deletePost(postId: string): Observable<any> {
    return this.http.delete<any>(`${this.gateUrl}/posts/${postId}/hide`, { withCredentials: true }).pipe(take(1));
  }

  public createPost(body: any): Observable<any> {
    return from(new PostRequest(this.http, `${this.gateUrl}/posts`, body).getResponse<any>());
  }

  public getUserPosts(userId: string, limit: number, page: number): Observable<any> {
    const params = new HttpParams()
      .set('limit', limit.toString())
      .set('page', page.toString());
      return this.http.get<any>(`${this.gateUrl}/posts/${userId}/list`, {
        params,
        withCredentials: true
      });
  }

  public patchPost(postId:string, updatedData: any): Observable<any> {
    return this.http.patch<any>(`${this.gateUrl}/posts/${postId}`, updatedData, { withCredentials: true })
    .pipe(
      mergeMap(() => this.getPostById(postId)),
      tap(() => this.postSavingProcessingSubject.next(false))
    );
  }

  public findAndModifyPost(post: Post): void {
    let index = this.feedPostsList.getValue().findIndex(item => item.id === post.id);
    if (index !== -1) this.feedPostsList.getValue()[index] = post;
  }

  public unshiftPostToPostsList(post: Post): void {
    if (this.feedPostsList.getValue().length) {
      this.feedPostsList.getValue().unshift(post);
    }
  }

  public likePost(postId:string): Observable<any> {
    return this.http.post<any>(`${this.gateUrl}/likes/posts/${postId}`,null,  { withCredentials: true });
  }

  public dislikePost(postId:string): Observable<any> {
    return this.http.delete<any>(`${this.gateUrl}/likes/posts/${postId}`,  { withCredentials: true });
  }

  public getLikesListForPost( limit: number, page: number, postId: string,): Observable<any> {
    const params = new HttpParams()
      .set('limit', limit.toString())
      .set('page', page.toString());
      return this.http.get<any>(`${this.gateUrl}/likes/posts/${postId}/list`, {
        params,
        withCredentials: true
      });
  }

  public scrollToPost(): void {
    if (isPlatformBrowser(this.platformId) && this.routesService.prevUrl.includes(this.currentPostVisited.getValue())) {
      setTimeout(() => {
        const element = document.getElementById(this.currentPostVisited.getValue())
        if (element) {
          element.scrollIntoView({ behavior: 'instant' });
          this.currentPostVisited.next(null);
        }
      }, 0)
    }
  }

  public clearPosts(): void {
    this.feedPostsList.next([]);
    this.feedReloadSubject.next(true);
  }

  public resetPostSettings(): void {
    this.feedPostSettings.next({limit: 10, page: 1});
  }

  private sortPostImagesByPosition(response: any): any {
    if (response.data && Array.isArray(response.data)) {
      response.data.forEach((item:any) => {
        if (item.images && Array.isArray(item.images)) {
          item.images.sort((a:any, b:any) => a.position - b.position);
        }
      });
    }
    return response;
  }

}
